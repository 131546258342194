<template>
    <section class="mx-5">
        <v-row>
            <v-col
            cols="12"
            md="12"
            >
                <h2 class="headline">Promedios</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="2"
                            sm="12"
                            >
                                <section class="d-flex flex-column">
                                    <div>
                                        <v-select
                                        v-model="$v.bimestre.$model"
                                        :error-messages="bimestreErrors"
                                        label="Bimestre"
                                        :items="bimestres"
                                        item-text="name"
                                        item-value="id"
                                        :loading="loadBimestre"
                                        :disabled="finalGrade"
                                        >
                                        </v-select>
                                    </div>
                                    <div class="lessTop">
                                        <v-checkbox
                                        v-model="finalGrade"
                                        label="Nota final"
                                        ></v-checkbox>
                                    </div>
                                </section>
                            </v-col>
                            <v-col
                            cols="12"
                            md="2"
                            sm="12"
                            >
                                <v-select
                                v-model="sede"
                                label="Sede"
                                :items="sedes"
                                item-text="nombre_sede"
                                item-value="id_sede"
                                :loading="loadSede"
                                :clearable="clear"
                                >
                                </v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="2"
                            sm="12"
                            >
                                <v-select
                                v-model="jornada"
                                label="Jornada"
                                :items="jornadas"
                                item-text="nombre_jornada"
                                item-value="id_jornada"
                                :loading="loadJornada"
                                :clearable="clear"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="3"
                            sm="12"
                            >
                                <v-select
                                v-model="level"
                                label="Nivel"
                                :items="levels"
                                item-text="nombre_nivel"
                                item-value="id_nivel"
                                :loading="loadLevel"
                                :clearable="clear"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="2"
                            sm="12"
                            >
                                <v-select
                                v-model="grade"
                                label="Grado/Carrera"
                                :items="grades"
                                item-text="nombre"
                                item-value="id"
                                :loading="loadGrade"
                                :clearable="clear"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="1"
                            sm="12"
                            >
                                <v-select
                                label="Sección"
                                :items="sections"
                                item-text="name"
                                item-value="id"
                                v-model="section"
                                :loading="loadSection"
                                :clearable="clear"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <section class="d-flex justify-center">
                            <div>
                                <v-btn
                                @click="submit"
                                :loading="dataLoader"
                                >
                                    Consultar
                                </v-btn>
                            </div>
                        </section>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mt-5">
            <v-col>
                <v-card v-show="showResults">
                    <v-card-text>
                        <v-row
                        class="mb-3"
                        >
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar"
                                hide-details
                                single-line
                                />
                            </v-col>
                        </v-row>
                        <template>
                            <v-data-table
                            :headers="headers"
                            :items="averages"
                            :search="search"
                            :items-per-page="-1"
                            ></v-data-table>
                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </section>
</template>

<script>
import { mapMutations,mapState } from 'vuex/dist/vuex.common.js';
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
    mixins: [validationMixin],
    data() {
        return {
            search: '',
            sede: '',
            jornada: '',
            level: '',
            grade: '',
            bimestre: 1,
            section: null,
            sedes: [],
            jornadas: [],
            levels: [],
            grades: [],
            sections: [],
            bimestres: [],
            loadSede: false,
            loadBimestre: false,
            loadJornada: false,
            loadLevel: false,
            loadGrade: false,
            loadSection: false,
            dataLoader: false,
            showResults: false,
            finalGrade: false,
            averages: [],
            headers: [
                {
                    text: 'Nombre',
                    value: 'name'
                },
                {
                    text: 'Promedio',
                    value: 'average'
                },
            ],
            clear: true
        }
    },

    validations: {
        bimestre: {required},
    },

    computed: {
        ...mapState(['schoolCycle']),
        sedeErrors () {
            const errors = []
            if (!this.$v.sede.$dirty) return errors
            !this.$v.sede.required && errors.push('Elige la sede')
            return errors
        },
        jornadaErrors () {
            const errors = []
            if (!this.$v.jornada.$dirty) return errors
            !this.$v.jornada.required && errors.push('Elige la jornada')
            return errors
        },
        nivelErrors () {
            const errors = []
            if (!this.$v.level.$dirty) return errors
            !this.$v.level.required && errors.push('Elige el nivel')
            return errors
        },
        gradeErrors () {
            const errors = []
            if (!this.$v.grade.$dirty) return errors
            !this.$v.grade.required && errors.push('Elige el/la grado/carrera')
            return errors
        },
        sectionErrors () {
            const errors = []
            if (!this.$v.section.$dirty) return errors
            !this.$v.section.required && errors.push('Elige la sección')
            return errors
        },
        bimestreErrors () {
            const errors = []
            if (!this.$v.bimestre.$dirty) return errors
            !this.$v.bimestre.required && !this.finalGrade && errors.push('Elige el bimestre')
            return errors
        }
    },

    methods: {
        ...mapMutations(['setLayout','setNotify','setLoading']),
        getDropdownsData(){
            this.loadSede = true
            this.loadJornada = true
            this.loadLevel = true
            this.loadBimestre = true


            this.axios.get('/getdropdownsdata')
            .then( (response) => {
                this.loadSede = false
                this.loadJornada = false
                this.loadLevel = false
                this.loadBimestre = false

                this.sedes = response.data.sedes
                this.jornadas = response.data.jornadas
                this.levels = response.data.niveles
                this.bimestres = response.data.bimestres
            })
            .catch( (error) => {
                this.loadSede = false
                this.loadJornada = false
                this.loadLevel = false
                this.loadBimestre = false
                console.log(error)
            })
        },
        getGrades(){
            this.loadGrade = true

            this.axios.get('/getgrades/'+this.level)
            .then( (response) => {
                this.loadGrade = false

                this.grades = response.data.grados
            })
            .catch( (error) => {
                this.loadGrade = false
                console.log(error)
            })
        },
        getSections(){
            if(this.level != '' && this.grade != ''){
                this.loadSection = true

                let grades = this.grades.filter((grade) => {
                    return grade.id == this.grade
                });
                this.sections = grades[0].sections
                this.loadSection = false
            }
        },
        submit() {
            this.showResults = false
            this.$v.$touch()

            if(!this.$v.$invalid || this.finalGrade){
                this.setLoading(true)
                let url = `/dashboard/average/student/${this.schoolCycle}`

                if(this.sede) url += `/${this.sede}`
                if(this.jornada) url += `/${this.jornada}`
                if(this.level) url += `/${this.level}`
                if(this.grade) url += `/${this.grade}`
                if(this.section) url += `/${this.section}`

                let final = (this.finalGrade) ? 1 : 0

                url += `?finalgrade=${final}`
                if(this.bimestre) url += `&bimestre=${this.bimestre}`

                this.axios.get(url)
                .then( (response) => {
                    this.setLoading(false)
                    this.averages = response.data.data

                    if(this.averages.length > 0)
                        this.showResults = true
                })
                .catch( (error) => {
                    this.showResults = false
                    this.setLoading(false)
                    console.log(error)
                })
            }
        },
        reset() {
            this.sede = ""
            this.jornada = ""
            this.level = ""
            this.grade = ""
            this.section = ""

            this.showResults = false
        }
    },

    watch: {
        "sede": function() {
            if(this.sede == null) {
                this.sede = ''
                this.jornada = ''
            }
        },
        "jornada": function() {
            if(this.jornada == null || this.jornada == '') {
                this.jornada = ''
                this.level = ''
            }
        },
        "level": function(){
            if(this.level == '' || this.level == null) {
                this.level = ''
                this.grade = ''
                this.grades = []
                this.sections = []
            }

            if(this.level != '') {
                this.sections = []
                this.grades = []
                this.getGrades()
            }
        },
        "grade": function(){
            if(this.grade == '' || this.grade == null) {
                this.grade = ''
                this.section = null
            }

            if(this.grade != '' && this.grades.length > 0){
                this.sections = []
                this.getSections()
            }
        },
        "finalGrade": function() {
            if(!this.finalGrade) {
                this.$v.$reset()
            }
        }
    },

    created() {
        this.setLayout('main-layout')
        this.getDropdownsData()
    }
}
</script>